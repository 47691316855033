import React, {useEffect, useState} from 'react';
import { Dialog, DialogPanel } from '@headlessui/react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { motion } from 'framer-motion';
import logo from "../images/IconOnly_Transparent_NoBuffer.png";
import {Link} from "react-router-dom";

const navigation = [
	{ name: 'Pricing', href: '/pricing' },
	{ name: 'About', href: '/about' },
	{ name: 'Contact Us', href: '/contact-us' },
];

export default function Header() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [darkMode, setDarkMode] = useState(false);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll, false);
		// Read the dark mode preference from localStorage on mount
		const storedDarkMode = localStorage.getItem('darkMode');
		if (storedDarkMode === 'true') {
			setDarkMode(true);
			document.documentElement.classList.add('dark');
		} else {
			setDarkMode(false);
			document.documentElement.classList.remove('dark');
		}
	}, []);

	const toggleDarkMode = () => {
		const newDarkMode = !darkMode;
		setDarkMode(!newDarkMode);
		document.documentElement.classList.toggle('dark');
		localStorage.setItem('darkMode', newDarkMode.toString());
	};

	const handleScroll = () => {
		if(window.location.pathname === "/signup" || window.location.pathname === "/login") {
			return;
		}
		const header = document.getElementById('header');
		if (window.scrollY > 50) {
			header!.classList.add('bg-white', 'shadow-md');
			header!.classList.remove('bg-transparent');
		} else {
			header!.classList.add('bg-transparent');
			header!.classList.remove('bg-white', 'shadow-md');
		}
	};

	return (
		<header id={"header"} className="bg-transparent transition-colors duration-100 fixed inset-x-0 top-0 z-50 stroke-gray-200 dark:bg-gray-800">
			<nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8">
				<div className="flex lg:flex-1 items-center">
					<Link to="/" className="-m-1.5 p-1.5 flex items-center">
						<img src={logo} alt="TrackNList Logo" className="h-8 w-auto"/>
						<span
							className="ml-2 text-xl tracking-wide font-avenir font-avenir-black bg-gradient-to-r from-softBlue via-brightPurple to-deepPurple bg-clip-text text-transparent hidden lg:block">TrackNList</span>
					</Link>
				</div>
				<div className="hidden lg:flex lg:gap-x-12">
					{navigation.map((item) => (
						<Link key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
							{item.name}
						</Link>
					))}
				</div>
				<div className="flex flex-1 items-center justify-end gap-x-6">
					<Link to="/login" className="hidden lg:block lg:text-sm lg:font-semibold lg:leading-6 lg:text-gray-900 dark:text-white">
						Log in
					</Link>
					<motion.div whileHover={{ scale: 1.1 }}>
						<Link
							to="/signup"
							className="rounded-md bg-softBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brightPurple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-softBlue"
						>
							Sign up
						</Link>
					</motion.div>
					<Link to="https://discord.com/invite/your-discord-link" target="_blank" rel="noopener noreferrer" className="hidden lg:block text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100">
						<FontAwesomeIcon icon={faDiscord} className="h-6 w-6" />
					</Link>
					<button onClick={toggleDarkMode} className="hidden lg:block text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100">
						{/*<FontAwesomeIcon icon={darkMode ? faSun : faMoon} className="h-6 w-6" />*/}
						{!darkMode ? <MoonIcon className={"h-6 w-6"} /> : <SunIcon className={"h-6 w-6"} />}
					</button>
				</div>
				<div className="flex lg:hidden">
					<button
						type="button"
						onClick={() => setMobileMenuOpen(true)}
						className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 dark:text-gray-300"
					>
						<span className="sr-only">Open main menu</span>
						<FontAwesomeIcon icon={faBars} className="h-6 w-6" />
					</button>
				</div>
			</nav>
			<Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden">
				<div className="fixed inset-0 z-[100]" />
				<DialogPanel className="fixed inset-y-0 right-0 w-full overflow-y-auto bg-white dark:bg-gray-800 z-[100] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
					<div className="flex items-center gap-x-6">
						<Link to="/" className="-m-1.5 p-1.5 flex items-center">
							{/*<FontAwesomeIcon icon={faBars} className="h-8 w-auto text-deepPurple" />*/}
							<span className="ml-2 text-lg font-bold text-gray-900 dark:text-white">TrackNList</span>
						</Link>
						<motion.div whileHover={{ scale: 1.1 }}>
							<Link
								to="/signup"
								className="ml-auto rounded-md bg-softBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brightPurple focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-softBlue"
							>
								Sign up
							</Link>
						</motion.div>
						<button
							type="button"
							onClick={() => setMobileMenuOpen(false)}
							className="-m-2.5 rounded-md p-2.5 text-gray-700 dark:text-gray-300"
						>
							<span className="sr-only">Close menu</span>
							<FontAwesomeIcon icon={faXmark} className="h-6 w-6" />
						</button>
					</div>
					<div className="mt-6 flow-root">
						<div className="-my-6 divide-y divide-gray-500/10">
							<div className="space-y-2 py-6">
								{navigation.map((item) => (
									<Link
										key={item.name}
										to={item.href}
										onClick={(e:any) => {setMobileMenuOpen(false)}}
										className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
									>
										{item.name}
									</Link>
								))}
							</div>
							<div className="py-6">
								<Link
									to="/login"
									className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
								>
									Log in
								</Link>
								<Link
									to="https://discord.gg/yCjfHZux"
									target="_blank"
									rel="noopener noreferrer"
									className="-mx-3 mt-2 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
								>
									<FontAwesomeIcon icon={faDiscord} className="mr-2 h-6 w-6" />
									Join Discord
								</Link>
								<button
									onClick={toggleDarkMode}
									className="-mx-3 mt-2 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700"
								>
									<FontAwesomeIcon icon={darkMode ? faSun : faMoon} className="mr-2 h-6 w-6" />
									{darkMode ? 'Light Mode' : 'Dark Mode'}
								</button>
							</div>
						</div>
					</div>
				</DialogPanel>
			</Dialog>
		</header>
	);
}
