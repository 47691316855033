import { useState } from 'react';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

export default function TermsAndConditions() {
	const [darkMode, setDarkMode] = useState(false);

	return (
		<div className={darkMode ? 'dark' : ''}>
			<div className="bg-white dark:bg-gray-800 px-6 py-32 lg:px-8">
				<div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 dark:text-gray-300">
					<h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
						Terms and Conditions
					</h1>
					<p className="mt-6 text-xl leading-8">
						Welcome to our application. By accessing or using our service, you agree to be bound by these terms and conditions.
					</p>
					<div className="mt-10 max-w-2xl">
						<p>
							These terms apply to all visitors, users, and others who access or use the service. Please read them carefully.
						</p>
						<ul role="list" className="mt-8 max-w-xl space-y-8 text-gray-600 dark:text-gray-400">
							<li className="flex gap-x-3">
								<CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600 dark:text-indigo-400" />
								<span>
                  <strong className="font-semibold text-gray-900 dark:text-white">Acceptance of Terms.</strong> By using our service, you agree to comply with and be bound by these terms.
                </span>
							</li>
							<li className="flex gap-x-3">
								<CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600 dark:text-indigo-400" />
								<span>
                  <strong className="font-semibold text-gray-900 dark:text-white">Changes to Terms.</strong> We reserve the right to modify these terms at any time. Your continued use of the service constitutes acceptance of the new terms.
                </span>
							</li>
							<li className="flex gap-x-3">
								<CheckCircleIcon aria-hidden="true" className="mt-1 h-5 w-5 flex-none text-indigo-600 dark:text-indigo-400" />
								<span>
                  <strong className="font-semibold text-gray-900 dark:text-white">User Responsibilities.</strong> You are responsible for your use of the service and for any consequences thereof.
                </span>
							</li>
						</ul>
						<p className="mt-8">
							If you have any questions about these terms, please contact us at support@example.com.
						</p>
						<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Governing Law</h2>
						<p className="mt-6">
							These terms shall be governed and construed in accordance with the laws of the jurisdiction in which we operate, without regard to its conflict of law provisions.
						</p>
						<figure className="mt-10 border-l border-indigo-600 dark:border-indigo-400 pl-9">
							<blockquote className="font-semibold text-gray-900 dark:text-white">
								<p>
									“By using our service, you agree to these terms and conditions.”
								</p>
							</blockquote>
							<figcaption className="mt-6 flex gap-x-4">
								<div className="text-sm leading-6">
									<strong className="font-semibold text-gray-900 dark:text-white">The Team</strong> – TrackNList
								</div>
							</figcaption>
						</figure>
						<p className="mt-10">
							Thank you for using our service. We hope you have a great experience.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
