import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import WhopColored from "../images/whop_logo_symbol_color.png";

const Login: React.FC = () => {
	const [formData, setFormData] = useState({
		usernameOrEmail: '',
		password: '',
		rememberMe: false,
	});
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const [message, setMessage] = useState<string | null>(null);
	const [messageSuccess, setMessageSuccess] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		document.getElementById("header")!.classList.add("bg-white");
		return () => {
			document.getElementById("header")!.classList.remove("bg-white");
		}
	}, []);

	const validateForm = () => {
		const newErrors: { [key: string]: string } = {};
		if (!formData.usernameOrEmail) {
			newErrors.email = 'Email is required';
		} else if (!/\S+@\S+.\S+/.test(formData.usernameOrEmail)) {
			newErrors.email = 'Email address is invalid';
		}
		if (!formData.password) {
			newErrors.password = 'Password is required';
		}
		return newErrors;
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = e.target;
		setFormData({
			...formData,
			[name]: type === 'checkbox' ? checked : value,
		});
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const validationErrors = validateForm();
		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return;
		}

		setIsSubmitting(true);
		try {
			const response = await fetch('https://api.tracknlist.com/api/login', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});

			if (response.ok) {
				setMessage('Login successful.');
				setMessageSuccess(true);
				const data = await response.json();
				window.location.href = `https://app.tracknlist.com/?token=${data.token}&refresh_token=${data.refresh}`
			} else {
				const errorData = await response.json();
				setMessage(errorData.error || 'An error occurred. Please try again.');
			}
		} catch (error) {
			setMessage('An error occurred. Please try again.');
		} finally {
			setIsSubmitting(false);
		}
	};

	const redirectToWhopOAuth = async () => {
		const clientId = '1_c8LXzyL1KNRvgTYfs6BdH7KS4GPCA1fH4dfIFKjh8';
		const redirectUri = encodeURIComponent('https://api.tracknlist.com/api/whop/callback');
		window.location.href = `https://whop.com/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code`;
	}

	return (
		<>
			<div className="flex min-h-full mt-[60px] flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50 dark:bg-gray-900">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
						Sign in to your account
					</h2>
				</div>

				<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
					<div className="bg-white dark:bg-gray-800 px-6 py-12 shadow sm:rounded-lg sm:px-12">
						{message && (
							<div className={`mb-4 text-sm ${messageSuccess ? "text-green-500 dark:text-green-400" : "text-red-600 dark:text-red-400"}`}>
								{message}
							</div>
						)}
						<form onSubmit={handleSubmit} className="space-y-6">
							<div>
								<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
									Email address
								</label>
								<div className="mt-2">
									<input
										id="email"
										name="usernameOrEmail"
										type="email"
										value={formData.usernameOrEmail}
										onChange={handleChange}
										required
										autoComplete="email"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 dark:bg-gray-700 sm:text-sm sm:leading-6"
									/>
									{errors.email && (
										<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.email}</p>
									)}
								</div>
							</div>

							<div>
								<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-300">
									Password
								</label>
								<div className="mt-2">
									<input
										id="password"
										name="password"
										type="password"
										value={formData.password}
										onChange={handleChange}
										required
										autoComplete="current-password"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 dark:bg-gray-700 sm:text-sm sm:leading-6"
									/>
									{errors.password && (
										<p className="mt-2 text-sm text-red-600 dark:text-red-400">{errors.password}</p>
									)}
								</div>
							</div>

							<div className="flex items-center justify-between">
								<div className="flex items-center">
									<input
										id="remember-me"
										name="rememberMe"
										type="checkbox"
										checked={formData.rememberMe}
										onChange={handleChange}
										className="h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600 dark:bg-gray-700"
									/>
									<label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900 dark:text-gray-300">
										Remember me
									</label>
								</div>

								<div className="text-sm leading-6">
									<Link to="/forgot-password" className="font-semibold text-indigo-600 hover:text-indigo-500 dark:text-indigo-400">
										Forgot password?
									</Link>
								</div>
							</div>

							<div>
								<button
									type="submit"
									disabled={isSubmitting}
									className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									{isSubmitting ? (
										<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
									) : (
										'Sign in'
									)}
								</button>
							</div>
						</form>

						<div>
							<div className="relative mt-10">
								<div aria-hidden="true" className="absolute inset-0 flex items-center">
									<div className="w-full border-t border-gray-200 dark:border-gray-600"/>
								</div>
								<div className="relative flex justify-center text-sm font-medium leading-6">
									<span
										className="bg-white dark:bg-gray-800 px-6 text-gray-900 dark:text-gray-300">Or continue with</span>
								</div>
							</div>

							<div className="mt-6 grid grid-cols-1 gap-4">
								<a
									href="https://api.tracknlist.com/api/google/login"
									className="flex w-full items-center justify-center gap-3 rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus-visible:ring-transparent"
								>
									<svg viewBox="0 0 24 24" aria-hidden="true" className="h-5 w-5">
										<path
											d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
											fill="#EA4335"
										/>
										<path
											d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
											fill="#4285F4"
										/>
										<path
											d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
											fill="#FBBC05"
										/>
										<path
											d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
											fill="#34A853"
										/>
									</svg>
									<span className="text-sm font-semibold leading-6">Google</span>
								</a>
							</div>

							<div className="mt-6 grid grid-cols-1 gap-4">
								<a
									onClick={redirectToWhopOAuth}
									className="flex w-full items-center cursor-pointer justify-center gap-3 rounded-md bg-white dark:bg-gray-700 px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 hover:bg-gray-50 dark:hover:bg-gray-600 focus-visible:ring-transparent"
								>
									<img src={WhopColored} alt={"whop logo"} className={"h-5 w-7"} />
									<span className="text-sm font-semibold leading-6">Whop</span>
								</a>
							</div>
						</div>
					</div>
					<p className="mt-10 text-center text-sm text-gray-500">
						Not a member?{' '}
						<Link to={"/signup"} className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
							Start a 30 day free trial
						</Link>
					</p>
				</div>
			</div>
		</>
	);
};

export default Login;
