import React from 'react';
import { ChatBubbleOvalLeftEllipsisIcon, HeartIcon } from '@heroicons/react/24/outline';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, BellIcon, EnvelopeOpenIcon } from '@heroicons/react/20/solid';
import productImage from "../images/product_image.png";

const features = [
	{
		name: 'Real-Time Inventory Tracking',
		description:
			'Keep your inventory up-to-date across all platforms with real-time synchronization. Never worry about overselling or stock discrepancies again.',
		icon: CloudArrowUpIcon,
	},
	{
		name: 'Secure and Reliable',
		description: 'Your data is protected with industry-standard security measures, ensuring that your inventory information is safe and secure.',
		icon: LockClosedIcon,
	},
	{
		name: 'Cross-Platform Listing',
		description: 'Easily list your products across multiple marketplaces with a single click. Save time and reach more customers effortlessly.',
		icon: ServerIcon,
	},
];

const otherFeatures = [
	{
		name: 'Email Tracking',
		description:
			'Monitor new emails for order notifications and updates. Keep track of all your communications in one place.',
		icon: EnvelopeOpenIcon,
	},
	{
		name: 'Notification/Alert',
		description:
			'Receive notifications and alerts for all actions across different platforms. Stay informed and never miss an important update.',
		icon: BellIcon, // Assuming you have a BellIcon or similar for notifications
	},
	{
		name: 'Cross-Platform Communication',
		description:
			'Communicate with customers across different marketplaces seamlessly. Manage all messages from a single dashboard.',
		icon: ChatBubbleOvalLeftEllipsisIcon,
	},
	{
		name: 'Customer Engagement',
		description:
			'Build strong relationships with your customers. Track interactions and provide personalized support to enhance customer satisfaction.',
		icon: HeartIcon,
	},
];

export default function Features() {
	return (
		<>
			<div className="overflow-hidden bg-white py-24 sm:py-32 dark:bg-gray-800 dark:text-white">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<div
						className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
						<div className="lg:ml-auto lg:pl-4 lg:pt-4">
							<div className="lg:max-w-lg">
								<h2 className="text-base font-semibold leading-7 text-deepPurple">TrackNList</h2>
								<p
									className="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">Efficient
									Inventory Management</p>
								<p className="mt-6 text-lg leading-8 text-gray-900 dark:text-gray-300">
									Simplify your inventory management and expand your reach with our powerful tools designed for modern
									sellers.
								</p>
								<dl
									className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-900 dark:text-gray-300 lg:max-w-none">
									{features.map((feature) => (
										<div key={feature.name} className="relative pl-9">
											<dt className="inline font-semibold text-gray-900 dark:text-white">
												<feature.icon aria-hidden="true" className="absolute left-1 top-1 h-5 w-5 text-deepPurple"/>
												{feature.name}
											</dt>
											{' '}
											<dd className="inline">{feature.description}</dd>
										</div>
									))}
								</dl>
							</div>
						</div>
						<div className="flex items-start justify-end lg:order-first">
							<img
								alt="Product screenshot"
								src={productImage}
								width={2432}
								height={1442}
								className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white py-24 sm:py-32 dark:bg-gray-800 dark:text-white">
				<div className="mx-auto max-w-7xl px-6 lg:px-8">
					<div
						className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
						<h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
							Enhance Your Selling Experience
						</h2>
						<dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
							{otherFeatures.map((feature) => (
								<div key={feature.name}>
									<dt className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
										<div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-deepPurple">
											<feature.icon aria-hidden="true" className="h-6 w-6 text-white"/>
										</div>
										{feature.name}
									</dt>
									<dd className="mt-1 text-base leading-7 text-gray-900 dark:text-white dark:text-gray-300">{feature.description}</dd>
								</div>
							))}
						</dl>
					</div>
				</div>
			</div>
		</>
	);
}
