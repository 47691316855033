import React from 'react';
import { JSX } from 'react/jsx-runtime';
import {Link} from "react-router-dom";

const navigation = [
	{
		name: 'Facebook',
		href: 'https://www.facebook.com',
		icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<path
					fillRule="evenodd"
					d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
					clipRule="evenodd"
				/>
			</svg>
		),
	},
	{
		name: 'Instagram',
		href: 'https://www.instagram.com',
		icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<path
					fillRule="evenodd"
					d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
					clipRule="evenodd"
				/>
			</svg>
		),
	},
	{
		name: 'X',
		href: 'https://www.twitter.com',
		icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
			</svg>
		),
	},
	{
		name: 'TikTok',
		href: 'https://www.tiktok.com',
		icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<path
					fillRule="evenodd"
					d="M12.75 2C13.9926 2 15.1834 2.47411 16.0355 3.31802C16.8876 4.16193 17.3636 5.35218 17.3636 6.59091V7.5C18.4545 7.5 19.5455 7.5 20.6364 7.5C20.6364 8.59091 20.6364 9.68182 20.6364 10.7727C19.5455 10.7727 18.4545 10.7727 17.3636 10.7727V14.1818C17.3636 16.0909 16.5909 17.9545 15.2727 19.2727C13.9545 20.5909 12.0909 21.3636 10.1818 21.3636C8.27273 21.3636 6.40909 20.5909 5.09091 19.2727C3.77273 17.9545 3 16.0909 3 14.1818C3 12.2727 3.77273 10.4091 5.09091 9.09091C6.40909 7.77273 8.27273 7 10.1818 7V10.4091C9.45455 10.4091 8.77273 10.6818 8.27273 11.1818C7.77273 11.6818 7.5 12.3636 7.5 13.0909C7.5 13.8182 7.77273 14.5 8.27273 15C8.77273 15.5 9.45455 15.7727 10.1818 15.7727C10.9091 15.7727 11.5909 15.5 12.0909 15C12.5909 14.5 12.8636 13.8182 12.8636 13.0909V6.59091H14.7727V10.7727C15.8636 10.7727 16.9545 10.7727 18.0455 10.7727V6.59091C18.0455 5.35218 17.5695 4.16193 16.7174 3.31802C15.8653 2.47411 14.6745 2 13.4318 2H12.75Z"
					clipRule="evenodd"
				/>
			</svg>
		),
	},
	{
		name: 'YouTube',
		href: 'https://www.youtube.com',
		icon: (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
			<svg fill="currentColor" viewBox="0 0 24 24" {...props}>
				<path
					fillRule="evenodd"
					d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
					clipRule="evenodd"
				/>
			</svg>
		),
	},
];

export default function Footer() {
	return (
		<footer className="bg-lightGray dark:bg-gray-800">
			<div className="mx-auto max-w-7xl px-6 pt-12 pb-2 md:flex md:items-center md:justify-between lg:px-8">
				<span className="ml-2 text-lg font-bold font-retreat text-gray-900 dark:text-white hidden lg:block">TrackNList</span>
			</div>
			<div className="mx-auto max-w-7xl px-6 pt-2 pb-12 md:flex md:items-center md:justify-between lg:px-8">
				<div className="flex justify-center space-x-6 md:order-2">
					{navigation.map((item) => (
						<a key={item.name} href={item.href}
						   className="text-gray-400 hover:text-softBlue dark:hover:text-brightPurple">
							<span className="sr-only">{item.name}</span>
							<item.icon aria-hidden="true" className="h-6 w-6"/>
						</a>
					))}
				</div>
				<div className="mt-8 md:order-1 md:mt-0">
					<div>
						<p className="text-center text-xs leading-5 text-gray-500 dark:text-gray-300">
							© {new Date().getFullYear()} TrackNList, Inc. All rights reserved.
						</p>
					</div>
					<div>
						<div className="flex justify-between md:justify-start space-x-4 mt-2 text-center text-xs leading-5 text-gray-500 dark:text-gray-300">
							<Link to="/terms-and-conditions"
							   className="text-gray-500 underline hover:text-softBlue dark:text-gray-300 dark:hover:text-brightPurple">
								Terms and Conditions
							</Link>
							<Link to="/privacy-policy"
							   className="text-gray-500 underline hover:text-softBlue dark:text-gray-300 dark:hover:text-brightPurple">
								Privacy Policy
							</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
}
